import React, { useEffect, useMemo } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { isSafari, isMobileSafari, isTablet, isIOS } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "@/redux/store";
import { withPageInitialLoad } from "@/libs/pageDataBridge";
import withAuthenticationChecking from "../../hocs/withAuthenticationChecking";

import { LOADER_TYPES, REDIRECTION_PAGES } from "../../constants";
import { getPageApi } from "../../redux/actions/page.action";
import CatalogPage from "../catalogPage";
import ErrorPage from "../errorPage";
import ProductListing from "../productListing";
import { dynamicPageSlice } from "@/redux/features";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { SaleWrapper } from "@/components/dynamic/dynamic-sale";
import { CatalogDataProps } from "../catalogPage/types";
import { ProductsListingPageData } from "../productListing/types";
import { SearchType } from "@/services/analytics/main/common/types";

const DynamicPage = () => {
  const pageData = useAppSelector(state => state.dynamicPage.data);
  const dispatch = useAppDispatch();
  const location = useLocation<
    Location & {
      fromSearch: boolean;
      searchPayload: {
        searchTerm: string;
        searchCategory: string;
        searchType: SearchType;
      };
    }
  >();
  const isIpadOnSafari = isTablet && isIOS && (isSafari || isMobileSafari);

  const contentToDisplay = useMemo(() => {
    switch (pageData.type) {
      case REDIRECTION_PAGES.catalog:
        return (
          <CatalogPage
            data={pageData.data as CatalogDataProps}
            meta={pageData.meta}
            contentSettings={{
              countryId: pageData.countryId,
              language: pageData.language
            }}
            subtype={pageData.subtype}
          />
        );

      case REDIRECTION_PAGES.productListing:
        return (
          //@ts-ignore
          <ProductListing
            providedListingData={pageData.data as ProductsListingPageData}
            metaData={pageData.meta}
            breadcrumbs={pageData.breadcrumbs}
            subtype={pageData.subtype}
          />
        );

      case REDIRECTION_PAGES.flashSale:
        return <SaleWrapper pageData={pageData as any} />;

      default: {
        return pageData.data ? <ErrorPage /> : null;
      }
    }
  }, [pageData]);

  useEffect(() => {
    return () => {
      if (!isIpadOnSafari) {
        dispatch(dynamicPageSlice.actions.resetDynamicPageData());
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    if (location.state?.fromSearch && pageData.data) {
      const { searchPayload } = location.state;
      const nbHits = (pageData.data as ProductsListingPageData).nbHits;
      if (nbHits) {
        CommonAnalyticsEvent.viewSearchResult({
          ...searchPayload,
          searchResultCount: nbHits
        });
      } else {
        const { searchTerm } = searchPayload;
        CommonAnalyticsEvent.emptySearchResult({
          searchText: searchTerm
        });
      }
    }
  }, [pageData]);

  return <div>{contentToDisplay}</div>;
};

export default withPageInitialLoad({
  pageName: "",
  page: withAuthenticationChecking(DynamicPage),
  strategy: async ({ store, params, query, language, url, ipcountry }) => {
    const countryId = store.getState().common.settings.countryId;

    await store.dispatch(
      getPageApi({
        url: qs.stringifyUrl(
          { url: url.replace(`/${params.language}/`, "/"), query },
          { skipNull: true, encode: false }
        ),
        language,
        countryId,
        ipcountry
      })
    );
  },
  ssr: true,
  loaderConfig: {
    type: LOADER_TYPES.DOTS,
    fullscreen: true,
    size: 16,
    qty: 3,
    background: "rgba(251, 251, 253, 0.4)"
  }
});
